import React from 'react';
import { FaSearchengin, FaTools, FaHandsHelping } from "react-icons/fa";
const data = [
  {
    icon: <FaSearchengin />,
    name: 'Project Assessment',
    variable: [
      'Business Consultancy',
      'Feasibility studies',
      "EPT Contract",
      "Financing"
    ]
  },
  {
    icon: <FaTools />,
    name: 'Product Development',
    variable: [
      'Engineering',
      'Procurement',
      "Testing",
      "Launching"
    ]
  },
  
  {
    icon: <FaHandsHelping />,
    name: 'After Sales',
    variable: [
      'Operation',
      'Maintenance',
      "Monitoring",
      "Training"
    ]
  }
];

export default data
