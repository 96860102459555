import React from "react"
import PropTypes from "prop-types"

const Footer = props => {
  const footerText = 'PT Karya Sabi Indonesia \u00A9';
  return (
    <div className="footer-wrapper">
      <p>{footerText}</p>
    </div>
  );
}

Footer.propTypes = {

}

export default Footer
