import React, { Fragment, PureComponent } from "react";
// import { Link } from "gatsby";
import _map from 'lodash/map'
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Jumbotron,
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  Container,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from 'reactstrap';
import founderData from 'src/constants/founder-data';
import productData from 'src/constants/product-data';
import customerJourneyData from 'src/constants/customer-journey';
import Footer from 'src/components/footer';
import("src/styles/index.sass")

class LandingPage extends PureComponent {
  constructor(props){
    super(props)
    this.state = { activeIndex: 0 };
  }

  next = () => {
    const nextIndex = this.state.activeIndex === productData.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous = () => {
    const nextIndex = this.state.activeIndex === 0 ? productData.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex = (newIndex) => {
    this.setState({ activeIndex: newIndex });
  }

  renderProductCarouselContent = () => {
    return _map(productData, (item) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.src}
        >
          <Card className="product-card">
            <CardBody>
              <CardTitle className="title-wrapper">
                <div className="icon">{item.icon}</div>
                <div className="title mt-3">
                  <p>{item.name}</p>
                </div>
              </CardTitle>
              <CardText className="description">
                {item.description}
              </CardText>
            </CardBody>
          </Card>
        </CarouselItem>
      );
    })
  }

  renderProductSection = () => {
    return _map(productData, (item) => {
      return (
        <Card className="product-card">
          <CardBody>
            <CardTitle className="title-wrapper">
              <div className="icon">{item.icon}</div>
              <div className="title mt-3">
                <p>{item.name}</p>
              </div>
            </CardTitle>
            <CardText className="description">
              {item.description}
            </CardText>
          </CardBody>
        </Card>
      )
    })
  };

  renderCustomerJourneySection = () => {
    return _map(customerJourneyData, (item) => {
      return (
        <Card>
          <CardBody>
            <CardTitle>
              {item.icon}{item.name}
            </CardTitle>
            <CardText>
              {
                _map(item.variable, (variable) => {
                  return <span>{variable}</span>
                })
              }
            </CardText>
          </CardBody>
        </Card>
      )
    })
  }

  renderFounderData = () => {
    return _map(founderData, (item) => {
      return (
        <Card key={item.name} className="founder-card my-4">
          <div className="founder-pic-wrapper">
            <CardImg className="founder-pic-wrapper" srcSet={`${item.picture}?q=50`} alt={`Founder - ${item.name}`} />
          </div>
          <CardBody className="founder-content">
            <CardTitle className="bold-text name">{item.name}</CardTitle>
            <CardText className="description">{item.description}</CardText>
          </CardBody>
        </Card>
      )
    })
  }

  render() {
    const { activeIndex } = this.state;
    return (
      <Fragment>
        {/*Jumbotron Section*/}
        <section id="section-jumbotron">
          <Jumbotron fluid className="full-page flex-center">
            <Container fluid>
              <div className="jumbotron-image-wrapper">
                <picture>
                  <source
                    type="image/webp"
                    data-srcset="image/sabi_logo_black.png?q=75&fm=webp"
                  />
                  <source
                    type="image/jpeg"
                    data-srcset="image/sabi_logo_black.png?q=75&fm=jpg"
                  />
                  <img
                    className=""
                    alt="sabi-logo"
                    srcSet="image/sabi_logo_black.png?q=75&amp;fm=jpg" />
                </picture>
              </div>
              <hr className="mt-0 mb-4 mx-5" />
              <h2 className="align-center subtitle">Personalize Your Needs</h2>
            </Container>
          </Jumbotron>
        </section>
        <div id="app-container">
          {/*Navbar*/}
          <div data-spy="scroll" data-offset="50">

            {/*Product Section*/}
            <section id="section-product">
              <div class="section-container">
                <h1 id="section-title"> Products </h1>
                <div className="product-wrapper container">
                  {/* Start Render for > Tablet*/}
                  <div className="d-none d-xl-block">
                    <div className="flex-center">
                      {this.renderProductSection()}
                    </div>
                  </div>
                  {/*End Render for > Tablet*/}
                  {/* Start Render for < Tablet*/}
                  <div className="product-carousel-wrapper d-xl-none">
                    <Carousel
                      activeIndex={activeIndex}
                      next={this.next}
                      previous={this.previous}
                      slide
                    >
                      <CarouselIndicators items={productData} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                      {this.renderProductCarouselContent()}
                      <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                      <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                    </Carousel>
                  </div>
                  {/*End Render for < Tablet*/}
                </div>
              </div>
            </section>

            {/*Customer Journey Section*/}
            <section id="section-customer-journey">
              <h1 id="section-title"> Customer Jouney </h1>
              <div className="customer-journey-wrapper container">
                {/*{this.renderCustomerJourneySection()}*/}
                <div className="image-wrapper d-none d-xl-block">
                  <picture>
                    <source
                      type="image/webp"
                      data-srcset="image/customer-journey.png?q=75&fm=webp"
                    />
                    <source
                      type="image/jpeg"
                      data-srcset="image/customer-journey.png?q=75&fm=jpg"
                    />
                    <img
                      className=""
                      alt="sabi-logo"
                      srcSet="image/customer-journey.png?q=75&amp&fm=jpg" />
                  </picture>
                </div>
                {/* Start Render for > Tablet*/}

                {/*End Render for > Tablet*/}
                <div className="image-wrapper d-xl-none">
                  <picture>
                    <source
                      type="image/webp"
                      data-srcset="image/customer-journey-mobile.png?q=75&fm=webp"
                    />
                    <source
                      type="image/jpeg"
                      data-srcset="image/customer-journey-mobile.png?q=75&fm=jpg"
                    />
                    <img
                      className=""
                      alt="sabi-logo"
                      srcSet="image/customer-journey-mobile.png?q=75&amp&fm=jpg" />
                  </picture>
                </div>
                {/* Start Render for < Tablet*/}

                {/*End Render for < Tablet*/}
              </div>
            </section>

            {/*Founder Section*/}
            <section id="section-founder">
              <div className="section-container">
                <h1 id="section-title"> Founder Profile </h1>
                <div className="founder-profile-wrapper container">
                  {this.renderFounderData()}
                </div>
              </div>
            </section>

            {/*Contact Us Section*/}
            <section id="section-contact-us">
              <div className="section-container">
                <h1 id="section-title"> Get in Touch </h1>
                <div className="wrapper">
                  <div className="container">
                    <h3 className="title">Phone</h3>
                    <span className="value">(+62)857-4033-5758</span>
                  </div>
                  <div className="container">
                    <h3 className="title">Location</h3>
                    <span className="value">Jalan Raya Jatiwaringin No.55, Kota Bekasi, Jawa Barat</span>
                  </div>
                  <div className="container">
                    <h3 className="title">Email</h3>
                    <span className="value">sabihq888@gmail.com</span>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        {/*Footer*/}
        <Footer />
      </Fragment>
    )
  }
}

LandingPage.propTypes = {}

export default LandingPage
