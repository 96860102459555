const data =  [
  {
    name: 'Ardiyansyah Djafar',
    picture: 'image/sollah-pic.jpg',
    description: `
         He is currently working at PT Jababeka Tbk as a VP Business Development, his strong record of solving marketing problems & various backgrounds become a unique combination of marketing expertise and knowledge that is more extensive than that of most marketers in the sector. Previously he also part of viral theme park startup with Seri-A rounding of $1.5M USD named Bounce Street Asia. He also actively involved in Government Sport Federation and as an dance athlete & coach with 15 yrs experience.
        `
  },
  {
    name: 'Raymond Haryanto',
    picture: 'image/raymond-pic.jpg',
    description: `
        He is currently working at Indodana his a full stack engineer who has accomplished 30+ projects since 2015 whose clients include Bogasari, Kewirusahaan Gonzaga, Mayora, CIMB, etc. He is Working closely to web and mobile technologies his experience makes him able to combine business idea, user experience, and technology in order to create a worthwhile product. Previously working at Aprisma Wirecard. Hard worker and mentally trained as an athlete, since he had been a cheerleading athlete for more than 9 years.
        `
  },
  {
    name: 'Rahadian Adinugroho',
    picture: 'image/raha-pic.jpg',
    description: `
         He is currently a software engineer in Traveloka. His position is the backend engineer of Airport Transport product. He ensures to deliver a production worthy and scalable system for the mission. Therefore, he is trusted for end-to-end design for the backend system feature, software implementation, and product provider integration. Aside from software engineering, he has interest in robotic, especially in automation. He also loves to ride his Vespa around the town, especially during Sunday morning where the road is empty.
        `
  }
];

export default data;
