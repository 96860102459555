import React from 'react';
import { FaBusinessTime, FaGlobe, FaHome } from "react-icons/fa";

const data = [
  {
    icon: <FaBusinessTime />,
    name: 'Business Consultancy',
    description: 'We believe that every people is having a spectacular idea. We are here to help you to realize your idea and even optimize it with the help of technology.'
  },
  {
    icon: <FaGlobe />,
    name: 'Web Application Development',
    description: 'As a good business, you need to have a system to control or centralize your data input and output. We are here to help you by providing web application development that is suitable with your needs.'
  },
  {
    icon: <FaHome />,
    name: 'Smart Home Integration',
    description: 'In this modern era, dreaming of having a smart home is not a dream anymore. We are able to modify your home starting from a little thing into the whole home integration.'
  }
];

export default data;
